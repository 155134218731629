<template lang="pug">
  tr(
    :key="index"
    data-test="candidate-row"
    :class="{'updated' : item.animation, 'app-table__row_expanded': isExpanded}"
    :style="{'background-color': candidateColor}"
  ).row-animation
    td
      v-checkbox(
        data-test="c-checkbox-select"
        hide-details
        :value="isSelected"
        :key="index" @change="select"
        @click.native="selectWithShiftkey({event: $event, item: item, index: index})"
      ).mt-0
      .row-labels
        label-primary(v-for="(label, index) in currentLabels" :key="index" :text="label.name || ''" :color="label.color")

    td.app-table__cell.app-table__cell_small
      .d-flex
        v-icon(@click="copyData(item)").hoverable mdi-content-copy
        v-icon(
          :class="{'expanded': isExpanded}"
          @click="goToEdit({expand, isExpanded})"
          data-test="candidate-open"
        ) mdi-chevron-right

    td( data-test="candidate-position" @click="goToEdit({expand, isExpanded})") {{ positionInList }}

    td
      candidate-color(:activeItems="[item]" :page="COLOR_PAGE")

    td(@click="goToEdit({expand, isExpanded})")
      div.table-date.table-date_additional.text-center {{ item.appPlacedAtFormatted }}
      div.table-date.table-date_additional.text-center {{ item.appPaidAtFormatted }}

    td(:style="{maxWidth: '100px'}" data-test="candidate-name" @click="goToEdit({expand, isExpanded})")
      | {{ item.fullName }}

    td(@click="goToEdit({expand, isExpanded})").cdn-row
      template(v-if="item.candidateNumber" )
        span.white-space-normal(:style="{maxWidth: '100px'}" v-html="candidateNumberSplicedOnTwoElement")
      candidate-label(:model="item")

    td(data-test="candidate-bday" @click="goToEdit({expand, isExpanded})").nowrap
      | {{ item.candidateBirthday }}
      div.white-space-normal(:style="{maxWidth: '100px'}") {{ item.candidateTel }}
    td(:style="{maxWidth: '100px'}" @click="goToEdit({expand, isExpanded})" ) {{ cbrAccount }}

    td(:style="{maxWidth: '100px'}" @click="goToEdit({expand, isExpanded})" ).text-center {{ item.applicationId }}

    td(@click="$emit('click:payments', item)" :class="{'has-package': hasPackage, 'not-has-package': notHasPackage}")
      check-color(:color="paymentColor")
      v-menu(top offset-y offset-x max-width="400" open-on-hover content-class="templates-tooltip")
        template(v-slot:activator="{ on }")
          span(v-on="on").hover-pointer.ml-1 {{ item.final_amount}} &euro;
        paymentsLog(:list="item.payments")
      div
        label-primary(text="Refunded" color="#ff0000" v-if="isRefunded")

    candidate-reserved-info(:item="item" @click="goToEdit({expand: expand, isExpanded: isExpanded})")

    td.journey
      span(v-if="item.exam.journey")
        v-tooltip(top )
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template {{ item.exam.journey.internal_name }}
          span {{ item.exam.journey.internal_name }}
      span(v-else) -

    td(:style="{maxWidth: '100px'}" @click="goToEdit({expand, isExpanded})" ) {{ item.productName }}
    td(:style="{maxWidth: '60px'}" @click="goToEdit({expand, isExpanded})")
      TaskRowLanguageCell(:lang="item.language")

    td(data-test="cbr-status" @click="goToEdit({expand, isExpanded})") {{ item.status }}

    td(v-html="item.course_type").course-type-td

    td
      LogEmailProvider(:getLogs="getSentEmails" v-slot:default="emailLogs")
        baseEmailTemplateProvider
          template(
            v-slot:default="baseEmail"
          )
            EmailTemplatesSendProvider(
              :ID="item.ID"
              :model="item"
              :formErrors="baseEmail.formErrors"
              v-slot:default="sendProvider"
              @send="emailLogs.addToLogs"
            )
              EmailTemplates(
                :items="baseEmail.list"
                :itemsLoading="baseEmail.listLoading"
                :editedTemplate="baseEmail.editedTemplate"
                :loadData="baseEmail.loadData"
                :logs="baseEmail.logs"
                :formErrors="baseEmail.formErrors"
                :processing="baseEmail.processing"
                :maxItemsShowing="11"
                @templateSelected="baseEmail.loadTemplateData"
                @onAddCustomTemplate="baseEmail.loadCreateTemplate"
                @submit="sendProvider.sendEmail"
                @save="baseEmail.post"
                @update="baseEmail.update"
                @remove="baseEmail.remove"
              )
                template(v-slot:tooltip)
                  v-menu(left offset-x max-width="400" open-on-hover content-class="templates-tooltip" @input="emailLogs.setTemplateTooltipShowing")
                    template(v-slot:activator="{ on }")
                      v-icon(v-on="on") mdi-email
                    templatesLog(:list="emailLogs.logs")
                template(v-slot:side)
                  v-col
                    h3.email-templates__subtitle Email logs
                    templatesLog(:list="emailLogs.logs" :loading="emailLogs.logsLoading")

    td.table-dates.cell-dates
      course-confirmed(:item="item" ).cell-dates

    td(@click="goToEdit({expand, isExpanded})").nowrap
      div(v-for="(request, index) in item.sortedFilteredRequests" :key="index").table-reservation
        reservation-date(
          :request="request"
          :filteredRequests="item.sortedFilteredRequests"
          :index="index"
          :exam-requests-total="item.exam_requests_total"
          :ID="item.ID"
          v-if="request.examDate && index < 2"
        )
    td.note-td
      note(:value="item.notes" :ID="item.ID")
</template>

<script>
import { COLOR_PAGE } from '@/util/const'
import CandidatesService from "@/app/admin/modules/candidates/core/candidates-service"
import copyMixin from "@/mixins/copy.mixin"
import checkBoolean from "@/components/global/CheckBoolean.vue"
import colorMixin from '@/mixins/color.mixin'
import CoursesInfo from "./CMCourses.vue"
import isRefundedMixin from "@/mixins/isRefunded.mixin"
import CheckColor from "@/components/global/CheckColor.vue"
import rowMixinGlobal from "@/app/admin/modules/candidates/core/candidateGlobalRow.mixin"
import TaskRowLanguageCell from "@/app/admin/modules/candidates/components/candidates/TaskRowLanguageCell.vue";

export default {
  name: 'CourseRow',

  mixins: [copyMixin, colorMixin, isRefundedMixin, rowMixinGlobal],

  inject: ['candidatesSvc'],

  props: {
    item: {
      type: Object,
      requred: true
    },
    index: Number,
    isSelected: Boolean,
    select: Function,
    expand: Function,
    isExpanded: Boolean,
    activeItems: Array,
    labels: Array,
    primaryPagination: Object

  },

  data: () => ({
    svc: new CandidatesService(),
    COLOR_PAGE: COLOR_PAGE.CANDIDATES
  }),


  computed: {
    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size;
    },

    // it's fix for 3cx plugin on chrome
    candidateNumberSplicedOnTwoElement() {
      let number = this.item.candidateNumber + ''
      let numberArr = number.split('')
      let halfLength = Math.ceil(numberArr.length / 2);
      let leftSide = numberArr.splice(0, halfLength);
      let html = `<span>${leftSide.join('')}</span><span>${numberArr.join('')}</span>`
      return html
    },

    cbrAccount() {
      return this.$store.getters['cbrUser/getNameByID'](this.item.accountUsername)
    },

    currentLabels() {
      if (!this.item.labels || !this.labels) return []
      return this.item.labels.map(labelID => {
        let sameLabel = this.labels.find(label => {
          if (label.ID === labelID) return label
        })
        if (sameLabel) return sameLabel
      })
    }
  },

  methods: {
    setColorId(colorId) {
      this.candidateColorID = colorId;
    },
    copyData(model) {
      let data = model.getSpreadsheetData(this.$store)
      this.copy(data)
    },

    getSentEmails() {
      return this.item.sentEmails
    },

    goToEdit({expand, isExpanded}) {
      this.$emit('onGoToEdit', {expand, isExpanded})
    },

    selectWithShiftkey({event, item, index}) {
      if (!this.activeItems.length || !event.shiftKey) return
      this.$emit('selectedWithShiftkey', {item: item, index: index})
    },
  },

  provide: function () {
    return {
      svc: () => this.candidatesSvc
    }
  },

  components: {
    TaskRowLanguageCell,
    reservationDate: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesReservedDate.vue'),
    paymentsLog: () => import('@/app/admin/modules/candidates/components/payments/CandidatesPaymentLog.vue'),
    EmailTemplatesProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailTemplatesProvider.vue'),
    EmailTemplates: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailTemplates'),
    checkColor: CheckColor,
    baseEmailTemplateProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailBaseProvider.vue'),
    EmailTemplatesSendProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailSendProvider.vue'),
    LogEmailProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidateEmailLogProvider.vue'),
    templatesLog: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesETempLog.vue'),
    note: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue'),
    candidateLabel: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesLabel.vue'),
    courseConfirmed: () => import('@/app/admin/modules/candidates/components/candidates/CandidateCourseConfirmed.vue'),
    showMore: () => import('@/components/global/showMoreTooltip.vue'),
    labelPrimary: () => import('@/components/global/LabelPrimary.vue'),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    willCome: () => import('./СMWillCome.vue'),
    candidateReservedInfo: () => import('@/app/admin/modules/course_day/components/body/CandidateReservedInfo.vue'),
    coursesInfo: CoursesInfo,
    checkBoolean
  }
}
</script>

<style lang="scss" scoped>

.row-animation {
  transition: all 1s;
  cursor: pointer;
  height: 60px;
}

.updated {
  background-color: rgba($success-color, .3) !important;
}

.expanded {
  transform: rotate(90deg);
  color: $primary-color !important;
}

.cdn-row {
  min-width: 100px;
}

.table-dates {
  width: 170px;
  line-height: 1.3;
}

.table-reservation {
  margin: 0 -4px;
}

.app-table td {
  font-size: 12px;
  line-height: 1.3;
}

.app-table__row_expanded {
  > td {
    border-top: 1px solid $primary-color;

    &:first-child {
      border-left: 1px solid $primary-color;
    }

    &:last-child {
      border-right: 1px solid $primary-color;
    }
  }
}

.white-space-normal {
  white-space: normal;
}

.note-td {
  max-width: 150px;
}

.journey {
  text-align: center;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-type-td {
  max-width: 80px;
}

.row-labels {
  position: absolute;
  margin-top: 5px;
}

.exchanged {
  background-color: $warning-color;
}

.mijn-reservation {
  background-color: $yellow-color;
}

.has-package {
  background-color: #9df397;
}

.not-has-package {
  background-color: #f39797;
}
</style>
